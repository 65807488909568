






































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import LeftNav from "../../components/left-nav.vue";
import NavBox from "../../components/nav-box.vue";
import TopSearch from "../../components/top-search.vue";
import { GetYianList, GetZhuantiList } from "@/request/yian";
@Component({
  components: {
    MainHeader,
    Empty,
    LeftNav,
    NavBox,
    TopSearch,
  },
})
export default class Name extends mixins(listMixin) {
  @Watch("jibing", { immediate: true })
  private jibingChange() {
    if (this.jibing && this.$refs.topSearch) {
      (this.$refs.topSearch as any).reset();
    }
  }
  private loading: any = false;
  private data: any = [];
  private keys: any = [
    {
      name: "症状体征",
      label: "症状体征",
    },
    {
      name: "中医证型",
      label: "中医证型",
    },
    {
      name: "中医治法",
      label: "中医治法",
    },
    {
      name: "中医方剂",
      label: "中医方剂",
    },
    {
      name: "中药饮片",
      label: "中药饮片",
    },
  ];
  private get jibing() {
    return this.$store.state.jibing;
  }
  private get type() {
    return this.$store.state.zhuanti;
  }
  private goDetail(items: any) {
    if (this.type == "医案病历") {
      this.$router.push({
        path: "/main/zsyy/yian/yian-detail",
        query: {
          id: items.medcase_id,
          type: this.type,
        },
      });
    } else {
      this.$router.push({
        path: "/main/zsyy/yian/yian-detail2",
        query: {
          id: items.entity_id,
          type: this.type,
        },
      });
    }
  }
  private filterChange(e: any) {
    this.filters["症状"] = e["症状"];
    this.filters["中医证型"] = e["中医证型"];
    this.filters["中医治法"] = e["中医治法"];
    this.filters["中医方剂"] = e["中医方剂"];
    this.filters["中药饮片"] = e["中药饮片"];
    this.filters["医家"] = e["医家"];
    this.filters["源文献"] = e["源文献"];
    this.filters["年代"] = e["年代"];
  }
  private goSearch(e: any) {
    this.search();
  }
  private getIdErr(data: any) {
    let ids: any = [];
    if (data && data.length > 0) {
      data.forEach((ele: any) => {
        ids.push(ele.id);
      });
    }
    return ids;
  }
  /**
   * @description 获取列表
   */
  getList() {
    let params: any = {
      search: this.filters.search,
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
      // bingzhong_id: this.jibing.child.id,
      症状: this.filters["症状"],
      中医证型: this.filters["中医证型"],
      中医治法: this.filters["中医治法"],
      中医方剂: this.filters["中医方剂"],
      中药饮片: this.filters["中药饮片"],
      医家: this.filters["医家"],
      文献: this.filters["源文献"],
      年代: this.filters["年代"],
    };
    localStorage.setItem("yianFilter", JSON.stringify(this.filters));
    this.loading = true;
    if (this.type == "医案病历") {
      params.bingzhong_id = this.jibing.child.id;
      GetYianList(this, params)
        .then((data: any) => {
          this.loading = false;
          this.data = data.medcases;
          this.filters.total_count = data.total_count;
          this.filters.total_page = data.total_page;
          if (this.data.length === 0) {
            this.ifShowEmpty = true;
          } else {
            this.ifShowEmpty = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      params["专题类型"] = this.type;
      GetZhuantiList(this, params)
        .then((data: any) => {
          this.loading = false;
          this.data = data.data;
          this.filters.total_count = data.total_count;
          this.filters.total_page = data.total_page;
          if (this.data.length === 0) {
            this.ifShowEmpty = true;
          } else {
            this.ifShowEmpty = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }

    return;
    this.data = [
      {
        id: "TS09888",
        诊次: [
          {
            诊次: "初诊",
            医家: "何立人",
            年代: "清代",
            源文献: "《何李人医案集》",
            症状: "头晕5年有余",
            中医证型: "肝肾阴虚证",
            治则治法: "清热解毒",
            中医方剂: "小青龙汤加减",
            中药饮片: "黄芪 3g  人参 5g  白术 9g   大麻 10g  煎服，每日2次。",
          },
        ],
      },
      {
        id: "TS09887",
        诊次: [
          {
            诊次: "三诊",
            医家: "何立人",
            年代: "清代",
            源文献: "《何李人医案集》",
            症状: "头晕5年有余",
            中医证型: "肝肾阴虚证",
            治则治法: "清热解毒",
            中医方剂: "小青龙汤加减",
            中药饮片: "黄芪 3g  人参 5g  白术 9g   大麻 10g  煎服，每日2次。",
          },
        ],
      },
      {
        id: "TS09881",
        诊次: [
          {
            诊次: "初诊",
            医家: "何立人",
            年代: "清代",
            源文献: "《何李人医案集》",
            症状: "头晕5年有余",
            中医证型: "肝肾阴虚证",
            治则治法: "清热解毒",
            中医方剂: "小青龙汤加减",
            中药饮片: "黄芪 3g  人参 5g  白术 9g   大麻 10g  煎服，每日2次。",
          },
          {
            诊次: "二诊",
            医家: "何立人",
            年代: "清代",
            源文献: "《何李人医案集》",
            症状: "头晕5年有余",
            中医证型: "肝肾阴虚证",
            治则治法: "清热解毒",
            中医方剂: "小青龙汤加减",
            中药饮片: "黄芪 3g  人参 5g  白术 9g   大麻 10g  煎服，每日2次。",
          },
        ],
      },
      {
        id: "TS09881",
        诊次: [
          {
            诊次: "二诊",
            医家: "何立人",
            年代: "清代",
            源文献: "《何李人医案集》",
            症状: "头晕5年有余",
            中医证型: "肝肾阴虚证",
            治则治法: "清热解毒",
            中医方剂: "小青龙汤加减",
            中药饮片: "黄芪 3g  人参 5g  白术 9g   大麻 10g  煎服，每日2次。",
          },
        ],
      },
    ];
    this.filters.total_count = 21;
    this.filters.total_page = 3;
    if (this.data.length === 0) {
      this.ifShowEmpty = true;
    } else {
      this.ifShowEmpty = false;
    }
  }
  /**
   * @description 初始化
   */
  mounted() {
    const d = localStorage.getItem("yianFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
